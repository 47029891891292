import React from "react"

import SEO from "../components/seo"

const days = [
  {label:"01", value:1},  {label:"02", value:2},  {label:"03", value:3},  {label:"04", value:4},  {label:"05", value:5},  {label:"06", value:6},
  {label:"07", value:7},  {label:"08", value:8},  {label:"09", value:9},  {label:"10", value:10}, {label:"11", value:11}, {label:"12", value:12},
  {label:"13", value:13}, {label:"14", value:14}, {label:"15", value:15}, {label:"16", value:16}, {label:"17", value:17}, {label:"18", value:18},
  {label:"19", value:19}, {label:"20", value:20}, {label:"21", value:21}, {label:"22", value:22}, {label:"23", value:23}, {label:"24", value:24},
  {label:"25", value:25}, {label:"26", value:26}, {label:"27", value:27}, {label:"28", value:28}, {label:"29", value:29}, {label:"30", value:30},
  {label:"31", value:31}
];

const months = [
  {label:"January", value:"January"},  {label:"February", value:"February"},  {label:"March", value:"March"},  {label:"April", value: "April"},
  {label:"May", value: "May"},  {label:"June", value:"June"},  {label:"July", value:"July"},  {label:"August", value:"August"},
  {label:"September", value:"September"}, {label:"October", value:"October"}, {label:"November", value:"November"}, {label:"December", value:"December"}
];

const years = [
  {label:new Date().getFullYear(), value: new Date().getFullYear()}, {label:new Date().getFullYear() + 1, value: new Date().getFullYear() + 1},
  {label:new Date().getFullYear() + 2, value: new Date().getFullYear() + 2}, {label:new Date().getFullYear() + 3, value: new Date().getFullYear() + 3},
  {label:new Date().getFullYear() + 4, value: new Date().getFullYear() + 4}, {label:new Date().getFullYear() + 5, value: new Date().getFullYear() + 5}
]

const ContactPage = () => (
  <React.Fragment>
    <SEO title="Contact" />
    <div id="contact">
      <div className="contact--section">
        <h1 data-sal="slide-right" data-sal-easing="ease" style={{"--sal-duration": "3s", "--sal-delay": "750ms"}}>Contact</h1>
        <p data-sal="slide-right" data-sal-easing="ease" style={{"--sal-duration": "2s", "--sal-delay": "750ms"}}>Our work ranges from small weddings to big ones. We have extensive experience in portrait, family photos and specially in wedding photography.</p>
      </div>

      <form name="contact-agba-weddings" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field"/>
        <input type="hidden" name="form-name" value="contact-agba-weddings"/>

        <div className="input-row" data-sal="slide-up" data-sal-delay="550" data-sal-easing="ease" data-sal-duration="500">
          <h3>General Info</h3>
          <label htmlFor="bride-groom-1">Bride / Groom full name</label>
          <input type="text" name="bride-groom-1" id="name-one" required placeholder="Kim Kardashian"/>
        </div>

        <div className="input-row" data-sal="slide-up" data-sal-delay="550" data-sal-easing="ease" data-sal-duration="500">
          <label htmlFor="bride-groom-2">Bride / Groom full name</label>
          <input type="text" name="bride-groom-2" id="name-two" required placeholder="Kanye West"/>
        </div>

        <div className="input-row" data-sal="slide-up" data-sal-delay="550" data-sal-easing="ease" data-sal-duration="500">
          <label htmlFor="social">Facebook or Instagram</label>
          <input type="url" name="social" id="social" placeholder="https://instagram.com/kimkardashian"/>
        </div>

        <div className="input-row" data-sal="slide-up" data-sal-delay="550" data-sal-easing="ease" data-sal-duration="500">
          <label htmlFor="email">E-mail</label>
          <input type="email" name="email" id="email" required placeholder="kim@kardashian.com"/>
        </div>

        <div className="input-row" data-sal="slide-up" data-sal-delay="550" data-sal-easing="ease" data-sal-duration="500">
          <label htmlFor="number-guests">Number of guests</label>
          <input type="number" name="number-guests" id="guests" required placeholder="500" min="1"/>
        </div>

        <div className="input-row" data-sal="slide-up" data-sal-delay="550" data-sal-easing="ease" data-sal-duration="500">
          <label htmlFor="planner">Wedding planner</label>
          <input type="text" name="planner" id="planner" placeholder="Name or link"/>
        </div>

        <div className="input-row date" data-sal="slide-up" data-sal-delay="550" data-sal-easing="ease" data-sal-duration="500">
          <h3>Time & place</h3>
          <label htmlFor="day">Wedding date</label>
          <select name="day" id="day" required defaultValue="Day">
            <option disabled value="Day">Day</option>
            {days.map(day => {
              return(
                <option key={`${day.label}${day.value}`} value={day.value}>{day.label}</option>
              )
            })}
          </select>
          <select name="month" id="month" required defaultValue="Month">
            <option disabled value="Month">Month</option>
            {months.map(month => {
              return(
                <option key={`${month.label}${month.value}`} value={month.value}>{month.label}</option>
              )
            })}
          </select>
          <select name="year" id="year" required defaultValue="Year">
            <option disabled value="Year">Year</option>
            {years.map(year => {
              return(
                <option key={`${year.label}${year.value}`} value={year.value}>{year.label}</option>
              )
            })}
          </select>
        </div>

        <div className="input-row" data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease" data-sal-duration="350">
          <label htmlFor="city">Wedding location</label>
          <input type="text" name="city" id="city" required placeholder="City / Town"/>
          <input type="text" name="venue" id="venue" required placeholder="Venue"/>
        </div>

        <div className="input-row" data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease" data-sal-duration="350">
          <div className="button-container">
            <button type="submit">Send</button>
          </div>
        </div>

      </form>

      <form style={{borderBottom: 'none', padding:0}} name="join-agba-weddings" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field"/>
        <input type="hidden" name="form-name" value="join-agba-weddings"/>

        <div className="input-row" data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease" data-sal-duration="350">
          <h3>Want to work with us?</h3>
          <label htmlFor="name">Full name</label>
          <input type="text" name="name" id="name" required placeholder="Annie Leibovitz"/>
        </div>

        <div className="input-row" data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease" data-sal-duration="350">
          <label htmlFor="join-social">Portfolio (Fb, Ig, Web)</label>
          <input type="url" name="join-social" id="join-social" required placeholder="https://instagram.com/annieleibovitz"/>
        </div>

        <div className="input-row" data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease" data-sal-duration="350">
          <label htmlFor="join-email">E-mail</label>
          <input type="email" name="join-email" id="join-email" required placeholder="annie@leibovitz.com"/>
        </div>

        <div className="input-row" data-sal="slide-up" data-sal-delay="150" data-sal-easing="ease" data-sal-duration="350">
          <div className="button-container">
            <button type="submit">Send</button>
          </div>
        </div>

      </form>
    </div>
  </React.Fragment>
)

export default ContactPage
